import getConfig from '../config';

const { paymentPlanRoute } = getConfig();

export const HOME = '/';

export const NEW_PAYMENT = '/new';
export const NEW_PAYMENT_REVIEW = `${NEW_PAYMENT}/review`;
export const NEW_PAYMENT_CARD = `${NEW_PAYMENT}/card`;
export const NEW_PAYMENT_BANK = `${NEW_PAYMENT}/bank`;
export const NEW_PAYMENT_CONFIRMATION = `${NEW_PAYMENT}/confirmation`;

export const COLLECTIONS = '/collections';
export const COLLECTIONS_REVIEW = `${COLLECTIONS}/review`;
export const COLLECTIONS_CARD = `${COLLECTIONS}/card`;
export const COLLECTIONS_BANK = `${COLLECTIONS}/bank`;
export const COLLECTIONS_CONFIRMATION = `${COLLECTIONS}/confirmation`;

export const PAYMENT_PLAN = paymentPlanRoute; // avoid circle dep
export const PAYMENT_PLAN_REVIEW = `${PAYMENT_PLAN}/review`;
export const PAYMENT_PLAN_CARD = `${PAYMENT_PLAN}/card`;
export const PAYMENT_PLAN_BANK = `${PAYMENT_PLAN}/bank`;
export const PAYMENT_PLAN_CONFIRMATION = `${PAYMENT_PLAN}/confirmation`;

export const AUTOPAY = '/autopay';
export const AUTOPAY_EDIT = `${AUTOPAY}/edit`;
export const AUTOPAY_NEW_CARD = `${AUTOPAY_EDIT}/new-card`;
export const AUTOPAY_NEW_BANK = `${AUTOPAY_EDIT}/new-bank`;

export const METHODS = '/methods';
export const METHOD_NEW = `${METHODS}/new`;
export const METHOD_NEW_CARD = `${METHOD_NEW}/card`;
export const METHOD_NEW_BANK = `${METHOD_NEW}/bank`;
export const METHOD_EDIT = `${METHODS}/edit/:token`;
export const NOW_METHODS = '/now/methods';
export const NOW_METHOD_NEW_CARD = `${NOW_METHODS}/new/card`;
export const NOW_METHOD_EDIT = `${NOW_METHODS}/edit/:token`;
export const NOW_METHOD_EDIT_CONFIRM = `${NOW_METHOD_EDIT}/confirmation`;
export const NOW_METHOD_NEW_CARD_CONFIRM = `${NOW_METHOD_NEW_CARD}/confirmation`;

export const NO_XFINITY_SERVICES = '/no-services';
export const NO_NOW_SERVICES = '/now/no-services';

export const SCHEDULED_PAYMENT = '/scheduled-payments';
export const SCHEDULED_PAYMENT_EDIT = `${SCHEDULED_PAYMENT}/:id`;
export const SCHEDULED_PAYMENT_REVIEW = `${SCHEDULED_PAYMENT_EDIT}/review`;
export const SCHEDULED_PAYMENT_CONFIRM = `${SCHEDULED_PAYMENT}/confirmation`;
export const SCHEDULED_PAYMENT_CANCEL_CONFIRM = `${SCHEDULED_PAYMENT}/cancel-confirmation`;

export const PAYMENT_SETUP = '/payment-setup';

export const TERMS_AUTOPAY = '/terms/autopay';
export const TERMS_STORED_PAYMENT = '/terms/stored-payment';
export const TERMS_ECOBILL = '/terms/ecobill';

export const resolveRoute = (url, params = {}) => url.replace(/:([a-zA-Z]+)/g, (_, sym) => params[sym]);

export const AUTHENTICATION_REQUIRED = '/auth-required';
export const LITE_PAY = '/lite';
