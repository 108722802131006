import React from 'react';
import { IconCheckGreen } from 'adc-ui-components';
import PageTitle from '../../components/PageTitle';

import getConfig from '../../config';

const { homeDomain } = getConfig();

const Confirmation = ({ location: { state } }) => {
  const isNotUpdateOrRemove = !state?.editAction;

  return (
    <>
      <div className="hgroup mb24">
        <IconCheckGreen className="mb6 svg-icon--42" />
        <PageTitle className="mb6">
          {`Your payment method has been ${state?.editAction || 'added'}`}
        </PageTitle>
        {isNotUpdateOrRemove && (
          <p className="body2">
            To use this payment method for NOW services,
            go to plans and update your payment method for each service.
          </p>
        )}
      </div>

      <div className="action action--responsive">
        <div className="action__item">
          <a href={`https://${homeDomain}/now/billing/wallet`} className="button button--primary">
            Back to Wallet
          </a>
        </div>
        <div className="action__item">
          <a href={`https://${homeDomain}/now/details`} className="button">
            Go to plans
          </a>
        </div>
      </div>
    </>
  );
};

export default Confirmation;
