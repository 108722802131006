import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { Route, Switch, Redirect } from 'react-router-dom';

import { sendPageloadEvent } from '../../actions/adobe';
import { sendChannelTracking } from '../../actions/channelTracking';
import { PAYMENT_START } from '../../helpers/channelTracking';

import { getAccount } from '../../actions/account';
import { getBill } from '../../actions/bill';
import { getInternetTier } from '../../actions/internetTier';
import { getPaymentInstruments } from '../../actions/instruments';
import { getLateFeeCredit } from '../../actions/latefeecredit';
import { resetPaymentState } from '../../actions/payment';

import { getShowFlexPromo, getShowPeacockPromo, getShowLiteBillPopup } from '../../helpers/account';
import { formatCurrency } from '../../helpers/formatText';
import { getPaymentMessage } from '../../helpers/paymentMessage';
import { shouldShowLateFeeCredit } from '../../helpers/lateFeeCredit';
import {
  newPaymentTrackingPayload,
  newPaymentReviewTrackingPayload,
  newPaymentConfirmationTrackingPayload,
} from '../../helpers/adobe';

import LiteDropDown from '../../components/LiteDropDown';
import Pushdown from '../../components/Pushdown';
import Bill from '../../components/svgs/Bill';
import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import {
  NEW_PAYMENT,
  NEW_PAYMENT_REVIEW,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CARD,
  NEW_PAYMENT_CONFIRMATION,
  AUTHENTICATION_REQUIRED,
} from '../../helpers/routes';

import PastDueNba, { PAST_DUE } from '../../components/nbas/PastDueNba';

import PaymentForm from './PaymentForm';
import Review from './Review';
import Confirmation from './Confirmation';
import AddInstrument from './AddInstrument';
import CardAndBankBlocked from '../Errors/CardAndBankBlocked';
import LiteBillModal from '../../components/LiteBillModal';

const renderLateFeeCredit = (location = {}, latefeecredit = {}) => {
  const { pathname } = location;

  if (pathname === NEW_PAYMENT_REVIEW) {
    return (
      <Pushdown
        title={`Set up automatic payments to get a one-time late fee
          credit of ${formatCurrency(latefeecredit.lateFee)}`}
        icon={(<span className="circle circle--large circle--minty"><Bill className="svg-icon--50" /></span>)}
      >
        {`You'll receive the credit on your next bill after your
          first automatic payment is processed.`}
      </Pushdown>
    );
  }

  if (pathname === NEW_PAYMENT) {
    return (
      <Pushdown
        title={`Pay what you owe and get a one-time late fee credit of
        ${formatCurrency(latefeecredit.lateFee)} when you set up
        automatic payments`}
        icon={(<span className="circle circle--large circle--minty"><Bill className="svg-icon--50" /></span>)}
      >
        You can set up automatic payments on the review page.
      </Pushdown>
    );
  }

  return null;
};

export class Payment extends Component {
  constructor() {
    super();

    this.state = {
      paymentFormData: {},
      channelTrackingSent: false,
    };

    this.handleSetPaymentFormData = this.handleSetPaymentFormData.bind(this);
  }

  componentDidMount() {
    const {
      handleGetAccount,
      handleGetBill,
      handleGetInternetTier,
      handleGetPaymentInstruments,
      handleGetLateFeeCredit,
      isLite,
    } = this.props;
    this.sendPageLoad();

    // need instruments for jwk
    handleGetPaymentInstruments();
    // need account for eligiblity
    handleGetAccount();
    // need bill for due date/amounts
    handleGetBill();
    // need tierData
    !isLite && handleGetInternetTier();
    // need late fee for banner (non-blocking)
    handleGetLateFeeCredit();
  }

  componentDidUpdate(prevProps) {
    this.sendPageLoad(prevProps);
    this.sendChannelTracking();
  }

  componentWillUnmount() {
    const {
      handleResetPaymentState,
    } = this.props;

    handleResetPaymentState();
  }

  sendChannelTracking() {
    const { loading, handleSendChannelTracking } = this.props;
    const { channelTrackingSent } = this.state;
    if (!loading && !channelTrackingSent) {
      handleSendChannelTracking({ id: PAYMENT_START, interactionType: 'BILLING_PAYMENTS' });
      this.setState(state => ({ ...state, channelTrackingSent: true }));
    }
  }

  scheduleTracking() {
    if (this.trackingTimeout) {
      clearTimeout(this.trackingTimeout);
    }
    const sendTracking = () => {
      const { loading } = this.props;
      if (loading) {
        // console.log('[Adobe] `loading` jitter from CMS, delaying more.');
        this.trackingTimeout = setTimeout(sendTracking, 125);
        return;
      }
      // console.log('[Adobe] Sending tracking payload');
      const {
        location: { pathname },
        bill,
        instruments: { instruments },
        showLateFeeCredit,
        review,
        confirm,
        isLite,
        status,
        showFlexPromo,
        peacockPromo,
        handleSendPageloadEvent,
      } = this.props;
      let trackingPayload = {};

      switch (pathname) {
        case NEW_PAYMENT:
          trackingPayload = newPaymentTrackingPayload(
            bill.summary,
            instruments,
            showLateFeeCredit,
            isLite,
          );
          break;
        case NEW_PAYMENT_REVIEW:
          trackingPayload = newPaymentReviewTrackingPayload(
            getPaymentMessage({
              bill,
              payment: review,
              status,
              type: 'review',
            }).message,
            showLateFeeCredit,
            isLite,
          );
          break;
        case NEW_PAYMENT_CONFIRMATION:
          trackingPayload = newPaymentConfirmationTrackingPayload(
            bill.summary,
            confirm,
            showFlexPromo,
            peacockPromo,
          );
          break;
        default:
          break;
      }

      // mock the computed match since we're in the parent
      const computedMatch = {
        isExact: true,
        path: pathname,
      };

      handleSendPageloadEvent(computedMatch, trackingPayload);
      this.trackingTimeout = null;
    };
    // console.log('[Adobe] Scheduling tracking');
    this.trackingTimeout = setTimeout(sendTracking, 0);
  }

  sendPageLoad(prevProps = {}) {
    const {
      loading,
      location: { pathname: nextPage },
    } = this.props;
    if (loading) return;
    const {
      location: { pathname: prevPage } = {},
      loading: wasLoading,
    } = prevProps;
    if (prevPage !== nextPage || wasLoading) {
      this.scheduleTracking();
    }
  }

  handleSetPaymentFormData(paymentFormData) {
    this.setState({ paymentFormData });
  }

  render() {
    const {
      account,
      bill,
      instruments: {
        bankblockStatus: isBankBlocked,
        cardblockStatus: isCardBlocked,
        accountAuthenticationRequired: isAuthRequired,
      },
      isHarness,
      isLite,
      macaroon,
      latefeecredit,
      location = {},
      nextBestActions,
      showLateFeeCredit,
      loading,
      canMakePayment,
      loadError,
      showFlexPromo,
      peacockPromo,
      showLiteBillPopup,
    } = this.props;
    const { paymentFormData } = this.state;
    if (loadError) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return (
        <PermsError
          isLite={isLite}
          isHarness={isHarness}
          error="!canMakePayment"
        />
      );
    }

    if (isAuthRequired && isLite) {
      return <Redirect to={AUTHENTICATION_REQUIRED} />;
    }

    if (isBankBlocked && isCardBlocked) {
      return (
        <CardAndBankBlocked
          billSummary={bill.summary}
          isLite={isLite}
          isHarness={isHarness}
        />
      );
    }

    return (
      <>
        {isLite && !loading && (
          <LiteDropDown
            account={account}
            bill={bill}
            macaroon={macaroon}
          />
        )}

        {!isLite && showLateFeeCredit && renderLateFeeCredit(location, latefeecredit)}
        {!loading && showLiteBillPopup && <LiteBillModal {...showLiteBillPopup} />}

        {loading && <PageSection><LoadingCard /></PageSection>}
        {!loading && (
          <Switch>
            <Route
              exact
              path={NEW_PAYMENT_BANK}
              render={matchProps => (
                <AddInstrument
                  {...matchProps}
                  paymentFormData={paymentFormData}
                />
              )}
            />
            <Route
              exact
              path={NEW_PAYMENT_CARD}
              render={matchProps => (
                <AddInstrument
                  {...matchProps}
                  paymentFormData={paymentFormData}
                />
              )}
            />
            <Route
              exact
              path={NEW_PAYMENT_REVIEW}
              render={matchProps => <Review {...matchProps} />}
            />
            <Route
              exact
              path={NEW_PAYMENT_CONFIRMATION}
              render={matchProps => (
                <Confirmation
                  showFlexPromo={showFlexPromo}
                  peacockPromo={peacockPromo}
                  {...matchProps}
                />
              )}
            />

            <Route
              render={matchProps => (
                <PaymentForm
                  {...matchProps}
                  nextBestActions={nextBestActions}
                  paymentFormData={paymentFormData}
                  showFlexPromo={showFlexPromo}
                  peacockPromo={peacockPromo}
                  handleSetPaymentFormData={this.handleSetPaymentFormData}
                />
              )}
            />
          </Switch>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    bill: { bill = {}, loading: billLoading },
    cms: {
      packages: {
        // xapModal will only be in the state if we called for the content, assume it's not there
        xapModal: {
          loading: xapModalLoading = false,
        } = {},
      } = {},
    },
    payment: { review, confirm },
    harness: { isHarness },
    instruments: {
      instruments,
      loading: instrumentsLoading,
      error: instrumentsError,
    },
    latefeecredit: { latefeecredit },
    internetTier: {
      loading: internetTierLoading,
      tierData = {},
    },
    account: {
      loading: accountLoading,
      account,
      account: {
        status,
        prepaid: isPrepaid,
      } = {},
      error: accountError,
    },
    auth: {
      macaroon,
      macaroon: {
        lite: isLite,
        walledgarden: isWalledGarden,
      } = {},
    },
  } = state;

  return {
    isLite,
    macaroon,
    isHarness,
    account,
    bill,
    instruments,
    latefeecredit,
    review,
    confirm,
    status,
    showFlexPromo: !isLite && getShowFlexPromo(account, tierData),
    peacockPromo: !isLite && getShowPeacockPromo(account, tierData),
    showLiteBillPopup: !isWalledGarden && isLite && getShowLiteBillPopup(account, bill),
    canMakePayment: !isPrepaid,
    loading: billLoading
      || instrumentsLoading
      || accountLoading
      || (!isLite && internetTierLoading)
      || xapModalLoading,
    showLateFeeCredit: shouldShowLateFeeCredit(bill, latefeecredit),
    loadError: instrumentsError || accountError,
    nextBestActions: [
      {
        id: PAST_DUE,
        component: PastDueNba,
        options: {
          showLink: false,
        },
      },
    ],
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSendPageloadEvent: sendPageloadEvent,
  handleGetAccount: getAccount,
  handleGetBill: getBill,
  handleGetInternetTier: getInternetTier,
  handleGetPaymentInstruments: getPaymentInstruments,
  handleGetLateFeeCredit: getLateFeeCredit,
  handleResetPaymentState: resetPaymentState,
  handleSendChannelTracking: sendChannelTracking,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Payment);
