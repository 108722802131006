import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Formik, Form } from 'formik';
import { Link, Route } from 'react-router-dom';
import { PageSection } from 'adc-ui-components';
import dateformat from 'dateformat';
import ErrorMessage from '../../components/ErrorMessage';

import { joinClass } from '../../helpers/component';
import { reviewPayment } from '../../actions/payment';
import { getPaymentInstruments } from '../../actions/instruments';

import {
  NEW_PAYMENT,
  NEW_PAYMENT_BANK,
  NEW_PAYMENT_CARD,
  NEW_PAYMENT_REVIEW,
} from '../../helpers/routes';
import { getIsToday } from '../../helpers/date';

import AddBank from '../../components/AddBank';
import AddCard from '../../components/AddCard';
import { getIsDisconnected } from '../../helpers/account';
import useCpc from '../../hooks/useCpc';
import { cpcDataToPaymentFormData } from '../../helpers/payments';
import { kibanaLog } from '../../helpers/logger';

const AddInstrumentForm = ({
  submitting,
  isHarness,
  initialValues,
  paymentFormData,
  history,
  handleReviewPayment,
  handleGetPaymentInstruments,
}) => {
  const cpc = useCpc();
  const { date } = paymentFormData;
  const requireStoredPaymentSelection = (date && !getIsToday(dateformat(date, 'yyyy-mm-dd')));

  useEffect(() => {
    if (!Object.keys(paymentFormData).length) {
      history.push(NEW_PAYMENT);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onAddInstrumentSubmit = async () => {
    try {
      const cpcPromise = await cpc.submitForm();
      const { cpcData: { submissionDetails: { cpcStatus } } } = cpcPromise;
      if (cpcStatus === 'ERROR') {
        // CPC component is handling displaying error msg
        return;
      }
      // force a get instruments here so that if the user goes back
      // to /new by clicking back or clicking "Make Another Payment"
      // on the confirmation page they will see the instrument they
      // just added.
      kibanaLog('cpc_submit_success');
      handleGetPaymentInstruments({ force: true, showLoading: false });
      handleReviewPayment({
        ...paymentFormData,
        ...cpcDataToPaymentFormData(cpcPromise),
      });
      history.push(NEW_PAYMENT_REVIEW);
    } catch (e) {
      // CPC component will handle displaying error msg
    }
  };

  return (
    <PageSection>
      <Formik
        initialValues={initialValues}
        onSubmit={onAddInstrumentSubmit}
      >
        {({
          submitCount,
          isValid,
        }) => (
          <Form
            noValidate
          >
            {!isValid && submitCount > 0 && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <div tabIndex="0" className="hide-focus">
              <ErrorMessage id="form-error" errorMessage="Please fix the form errors to continue" className="visuallyhidden" />
            </div>
            )}

            <div className="payment-section">
              <>
                <Route
                  exact
                  path={NEW_PAYMENT_BANK}
                  render={() => (
                    <AddBank
                      displayStoredPaymentOption
                      requireStoredPaymentSelection={requireStoredPaymentSelection}
                    />
                  )}
                />
                <Route
                  exact
                  path={NEW_PAYMENT_CARD}
                  render={() => (
                    <AddCard
                      displayStoredPaymentOption
                      requireStoredPaymentSelection={requireStoredPaymentSelection}
                    />
                  )}
                />
              </>

            </div>

            <div className="action action--right pt6">
              <div className="action__item">
                <button
                  type="submit"
                  className={joinClass('button button--primary', submitting && 'is-loading')}
                  disabled={submitting}
                >
                  Continue
                </button>
              </div>
              {!isHarness && (
              <div className="action__item">
                <Link to={NEW_PAYMENT} className="button">Back</Link>
              </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </PageSection>
  );
  // }
};

const mapStateToProps = (state, ownProps) => {
  const {
    auth: {
      macaroon: {
        lite: isLite,
      } = {},
    },
    account: {
      account: {
        status,
      } = {},
    },
    harness: { isHarness },
    instruments: { submitting },
    userMessages: {
      error: errorMessage,
      showModal,
    },
  } = state;

  const { location } = ownProps;

  const type = location.pathname === NEW_PAYMENT_BANK ? 'Bank' : 'PaymentCard';

  const initialValues = {};

  if (type === 'Bank') {
    initialValues.account = 'Checking';
  }

  const isDisconnected = getIsDisconnected(status);

  return {
    submitting,
    type,
    initialValues: {
      ...initialValues,
      billingAddressCheckbox: false,
      savePayment: false,
      isDefault: 'no',
    },
    errorMessage: !showModal && errorMessage,
    isHarness,
    canStoreInstruments: !isLite && !isDisconnected,
  };
};

const mapDispatchToProps = dispatch => ({
  handleGetPaymentInstruments: bindActionCreators(getPaymentInstruments, dispatch),
  handleReviewPayment: bindActionCreators(reviewPayment, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddInstrumentForm);
