import React from 'react';
import CmsImage from './CmsImage';
import CmsCta from './CmsCta';

const DefaultLayout = ({
  layout: {
    heading,
    subheading,
    description,
    cta: ctas = [],
    images = [],
  } = {},
}) => {
  const image = images.find(({ name }) => name === 'logo');
  return (
    <div className="card card--cms-promo card--flex-at-768">
      {!!image && (
        <div className="card__image">
          <CmsImage image={image} />
        </div>
      )}
      <div className="card__content">
        {(heading || subheading) && (
          <div className="hgroup">
            {heading && <h1 className="body1">{heading}</h1>}
            {subheading && <h2 className="body2">{subheading}</h2>}
          </div>
        )}
        {!!description && <p className="body3">{description}</p>}
        <div className="card__content__action">
          {ctas.map(cta => (
            <CmsCta key={cta.url} cta={cta} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DefaultLayout;
