import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { Route, Switch } from 'react-router-dom';

import { sendChannelTracking } from '../../actions/channelTracking';
import { XFINITY_ASSISTANCE_PLAN_INITIATED } from '../../helpers/channelTracking';
import { sendPageloadEvent } from '../../actions/adobe';
import { getAccount } from '../../actions/account';
import { getPaymentInstruments } from '../../actions/instruments';
import { resetPaymentState } from '../../actions/payment';
import { getLtipOptions } from '../../actions/ltip';
import { getCollections } from '../../actions/collections';

import { getIsXapExit } from '../../helpers/xap';
import withCmsContent from '../../helpers/withCmsContent';
import { getPaymentMessage } from '../../helpers/paymentMessage';

import {
  newPaymentTrackingPayload,
  newPaymentReviewTrackingPayload,
  newPaymentConfirmationTrackingPayload,
} from '../../helpers/adobe';

import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import {
  COLLECTIONS,
  COLLECTIONS_REVIEW,
  COLLECTIONS_BANK,
  COLLECTIONS_CARD,
  COLLECTIONS_CONFIRMATION,
} from '../../helpers/routes';

import Form from './Form';
import Review from './Review';
import Confirmation from './Confirmation';
import AddInstrument from './AddInstrument';
import CardAndBankBlocked from '../Errors/CardAndBankBlocked';

import { flagEnabled } from '../../helpers/featureFlags';

class PaymentPlan extends Component {
  constructor() {
    super();

    this.state = {
      paymentFormData: {},
      channelTrackingSent: false,
    };

    this.handleSetPaymentFormData = this.handleSetPaymentFormData.bind(this);
  }

  componentDidMount() {
    const {
      handleGetAccount,
      handleGetPaymentInstruments,
      handleGetLtipOptions,
      handleGetCollections,
      pauseConsentId,
      cantUseCollections,
    } = this.props;

    this.sendPageLoad();

    // need instruments for jwk
    handleGetPaymentInstruments();
    // need account for eligiblity
    handleGetAccount();
    // needed to long-term installment plans
    !cantUseCollections && handleGetLtipOptions();
    pauseConsentId && handleGetCollections();
  }

  componentDidUpdate(prevProps) {
    this.sendPageLoad(prevProps);
    this.sendChannelTracking();
  }

  componentWillUnmount() {
    const {
      handleResetPaymentState,
    } = this.props;
    handleResetPaymentState();
  }

  sendChannelTracking() {
    const { loading, handleSendChannelTracking } = this.props;
    const { channelTrackingSent } = this.state;
    if (!loading && !channelTrackingSent) {
      handleSendChannelTracking({ id: XFINITY_ASSISTANCE_PLAN_INITIATED, interactionType: 'BILLING_PAYMENTS' });
      this.setState(state => ({ ...state, channelTrackingSent: true }));
    }
  }

  scheduleTracking() {
    if (this.trackingTimeout) {
      clearTimeout(this.trackingTimeout);
    }
    const sendTracking = () => {
      const { loading } = this.props;
      if (loading) {
        this.trackingTimeout = setTimeout(sendTracking, 125);
        return;
      }
      const {
        location: { pathname },
        instruments: { instruments },
        review,
        confirm,
        isLite,
        status,
        handleSendPageloadEvent,
        account,
      } = this.props;
      let trackingPayload = {};
      const isXapExit = getIsXapExit(account);
      const showLateFeeCredit = false;
      const showFlexPromo = false;
      const peacockPromo = {};

      switch (pathname) {
        case COLLECTIONS:
          trackingPayload = newPaymentTrackingPayload(
            instruments,
            showLateFeeCredit,
            isLite,
            isXapExit,
          );
          break;
        case COLLECTIONS_REVIEW:
          trackingPayload = newPaymentReviewTrackingPayload(
            getPaymentMessage({
              payment: review,
              status,
              type: 'review',
              showLateFeeCredit,
              isLite,
            }).message,
          );
          break;
        case COLLECTIONS_CONFIRMATION:
          trackingPayload = newPaymentConfirmationTrackingPayload(
            undefined,
            confirm,
            showFlexPromo,
            peacockPromo,
          );
          break;
        default:
          break;
      }

      // mock the computed match since we're in the parent
      const computedMatch = {
        isExact: true,
        path: pathname,
      };

      handleSendPageloadEvent(computedMatch, trackingPayload);
      this.trackingTimeout = null;
    };
    this.trackingTimeout = setTimeout(sendTracking, 0);
  }

  sendPageLoad(prevProps = {}) {
    const {
      loading,
      location: { pathname: nextPage },
    } = this.props;
    if (loading) return;
    const {
      location: { pathname: prevPage } = {},
      loading: wasLoading,
    } = prevProps;
    if (prevPage !== nextPage || wasLoading) {
      this.scheduleTracking();
    }
  }

  handleSetPaymentFormData(paymentFormData) {
    this.setState({ paymentFormData });
  }

  render() {
    const {
      instruments: {
        bankblockStatus: isBankBlocked,
        cardblockStatus: isCardBlocked,
      },
      loading,
      canMakePayment,
      loadError,
      cmsLoading,
      cantUseCollections,
    } = this.props;
    const { paymentFormData } = this.state;

    if (loadError) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return <PermsError error="!canMakePayment" />;
    }

    if (isBankBlocked && isCardBlocked) {
      return <CardAndBankBlocked />;
    }

    if (loading || cmsLoading) {
      return <PageSection><LoadingCard /></PageSection>;
    }
    return (
      <Switch>
        <Route
          exact
          path={COLLECTIONS_BANK}
          render={matchProps => (
            <AddInstrument
              {...matchProps}
              paymentFormData={paymentFormData}
            />
          )}
        />
        <Route
          exact
          path={COLLECTIONS_CARD}
          render={matchProps => (
            <AddInstrument
              {...matchProps}
              paymentFormData={paymentFormData}
            />
          )}
        />
        <Route
          exact
          path={COLLECTIONS_REVIEW}
          component={Review}
        />
        <Route
          exact
          path={COLLECTIONS_CONFIRMATION}
          component={Confirmation}
        />

        <Route
          render={matchProps => (
            <Form
              {...matchProps}
              paymentFormData={paymentFormData}
              handleSetPaymentFormData={this.handleSetPaymentFormData}
              cantUseCollections={cantUseCollections}
            />
          )}
        />
      </Switch>
    );
  }
}

const mapStateToProps = ({
  auth: {
    pauseConsentId,
  },
  instruments: {
    instruments,
    loading: instrumentsLoading,
    error: instrumentsError,
  },
  account: {
    loading: accountLoading,
    account,
    account: {
      status,
      prepaid: isPrepaid,
      xap: {
        ltip,
        servicePause,
        xapExit,
      } = {},
    } = {},
    error: accountError,
  },
  auth: {
    macaroon: {
      lite: isLite,
    } = {},
  },
  ltip: {
    options: ltipOptions,
    loading: ltipOptionsLoading,
  } = {},
  collections: {
    loading: collectionsLoading,
  },
}) => {
  // User cannot use collections unless
  const cantUseCollections = !accountLoading && !(
    // collections2 is enabled, and
    flagEnabled('global.collections2', { defaultValue: true })
    && (
      // User is here for service pause
      servicePause === 'ELIGIBLE' && pauseConsentId
    )
  );
  return {
    isLite,
    pauseConsentId,
    account,
    instruments,
    status,
    canMakePayment: !isPrepaid,
    ltipOptions,
    cantUseCollections,
    loading: instrumentsLoading
      || accountLoading
      || (!cantUseCollections && ltipOptionsLoading)
      || (!cantUseCollections && collectionsLoading),
    loadError: instrumentsError || accountError,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleSendPageloadEvent: sendPageloadEvent,
  handleGetAccount: getAccount,
  handleGetPaymentInstruments: getPaymentInstruments,
  handleResetPaymentState: resetPaymentState,
  handleSendChannelTracking: sendChannelTracking,
  handleGetLtipOptions: getLtipOptions,
  handleGetCollections: getCollections,
}, dispatch);

export default withCmsContent(null, {
  // Just getting the `cmsLoading` property
  'ltip-payment-plan': {},
  'collections-2': {},
})(connect(mapStateToProps, mapDispatchToProps)(PaymentPlan));
