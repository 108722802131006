import { useEffect, useState } from 'react';

import Cookies from 'js-cookie';
import featureFlags, { flagEnabled } from './featureFlags';
import { isSpanish } from './language';
import store from '../store';
import { setAccountIndex } from './account';
import { isNowPage } from './now';

const hasGlobalNavCookie = Cookies.get('xpgn') === '1';

export const shouldUseGlobalNav = () => {
  // Reload if the cookie changes mid-session
  if ((Cookies.get('xpgn') === '1') !== hasGlobalNavCookie) {
    window.location.reload();
  }
  return (
    flagEnabled('enableGlobalNav')
    && (
      hasGlobalNavCookie
      || flagEnabled('forceGlobalNav')
    )
  );
};

const device = (new URLSearchParams(window.location.search).get('device') || '').toLowerCase();
const isHarness = (device === 'ios' || device === 'android');

/**
 * Should the app use the global nav.  Use this when
 * your component just wants to know, and doesn't expect
 * to drop an `xc-*` element.
 * @returns {boolean | undefined}
 *  true for new nav
 *  false for old nav
 *  undefined for not ready to choose yet
 */
export const useIsGlobalNav = () => {
  const [isGlobalNav, setIsGlobalNav] = useState(undefined);
  useEffect(() => {
    featureFlags.onReady(() => {
      setIsGlobalNav(shouldUseGlobalNav());
    });
  }, []);
  return isGlobalNav;
};

export const isGlobalNavForClassComp = async () => {
  let isGlobalNav;
  if (!isHarness) {
    isGlobalNav = await featureFlags.onReady(() => shouldUseGlobalNav());
  }
  return isGlobalNav;
};

const HAS_CUSTOM = !!window.customElements;
const MAW_READY = 'maw-bill-pay--ready';
const allowLegacy = false;
export const showPage = () => {
  document.body.classList.add(MAW_READY);
  isReady = true;
};

let isReady = false;

let polarisScript = null;
/**
 * Should the app use the global nav.  Use this when
 * your component expects to drop an `xc-*` element.
 * @returns {boolean | undefined}
 *  true for new nav
 *  false for old nav
 *  undefined for not ready to choose yet
 */
export const usePolaris = () => {
  const isGlobalNav = useIsGlobalNav();
  useEffect(() => {
    if (isGlobalNav) {
      const checkAccount = () => {
        // We've already seen this through to completion; skip
        if (isReady) return null;
        const polaris = document.querySelector('xc-header')?.data?.polaris;
        // Get the macaroon and its load state from the store
        const { macaroon: { accounts } = {}, loading } = store.getState().auth || {};
        // Macaroon or Polaris is still loading; skip
        if (loading || !polaris?.user) return null;
        // Get the expected account number from Polaris
        const polarisAccount = polaris?.user?.accountNumber;
        // Get the expected account number from the store
        const myAccount = (
          accounts?.find(({ isSelectedAccount }) => isSelectedAccount)?.accountNumber
        );
        // Polaris and Macaroon are ready, but there's no account on one or the other
        // Either we'll be redirected to login, or there's an error page, either way
        // show the page and clean up.
        if (!polarisAccount || !myAccount) return cleanup();
        // Accounts match; show the page
        if (myAccount === polarisAccount) return cleanup();
        // Accounts don't match; find and set the correct account index and reload
        // Stay hidden during this time, as a spurious error will display.
        setAccountIndex(accounts.findIndex(({ accountNumber }) => (
          accountNumber === polarisAccount
        )), false);
        window.location.reload();
        return null;
      };

      // Listen on changes to Polaris data
      window.addEventListener('polaris-data-changed', checkAccount);
      const unlisten = () => window.removeEventListener('polaris-data-changed', checkAccount);
      // Listen on changes to the Macaroon
      const unsubscribe = store.subscribe(checkAccount);
      const cleanup = () => {
        // Remove the listeners to ease overhead; the macaroon
        // account doesn't change between page loads, and once
        // polaris is ready, neither will it change.
        unlisten();
        unsubscribe();
        showPage();
      };
    } else if (isGlobalNav === false) {
      // Not gonna load polaris; just show the page
      showPage();
    }
    // isGlobalNav can also be undefined, meaning flags are
    // still loading.  Nothing to do in that case.
    if (!polarisScript && isGlobalNav !== undefined) {
      // Don't pull in legacy polaris unless told to
      if (!isGlobalNav && !allowLegacy) return;
      const el = document.createElement('script');
      const domain = (
        window.MAW.env === 'prod'
        || window.MAW.env === 'preprod'
      )
        ? 'https://polaris.xfinity.com/'
        : 'https://polaris.staging.xfinity.com/';
      const { pathname } = window.location;
      let path = '';
      if (isNowPage(pathname)) {
        path = 'now/';
      } else if (isGlobalNav) {
        path = 'globalnav/';
      }
      const lang = isSpanish(window.location.toString()) ? '.es' : '';
      const file = `polaris.${HAS_CUSTOM ? 'wc' : 'es5'}${lang}.js`;
      el.setAttribute('async', 'async');
      el.src = `${domain}${path}${file}`;
      polarisScript = el;
      document.querySelector('head').appendChild(el);
    }
  }, [isGlobalNav]);
  return isGlobalNav;
};
