import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { forceAuth } from 'maw-fetch';
import Modal from './Modal';
import { extendSession } from '../actions/session';
import ModalText from './ModalText';

export const ExtendSessionDlg = ({
  timeLeft,
  handleExtendSession,
}) => {
  const [suppressed, setSuppressed] = useState(false);

  const open = !suppressed && timeLeft !== undefined && timeLeft <= 60;
  return (
    <Modal isOpen={open} onRequestClose={() => setSuppressed(true)}>
      <div tracking-module="extend-session">
        <ModalText
          heading="Your session is about to end"
          paragraph={`For added security, you'll be automatically
            logged out and redirected to the login screen in 60 seconds.`}
        />
        <div className="action action--centered">
          <div className="action__item">
            <button
              type="button"
              className="button button--primary"
              onClick={handleExtendSession}
            >
              Continue Session
            </button>
          </div>
          <div className="action__item">
            <button
              type="button"
              className="button"
              onClick={forceAuth}
            >
              End Session
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = ({
  session: { timeLeft },
}) => ({ timeLeft });

const mapActionsToProps = dispatch => bindActionCreators({
  handleExtendSession: extendSession,
}, dispatch);

export default connect(mapStateToProps, mapActionsToProps)(ExtendSessionDlg);
