import React, { Component } from 'react';
import { connect } from 'react-redux';
import { PageSection, LoadingCard } from 'adc-ui-components';
import { bindActionCreators } from 'redux';
import { Switch, Redirect } from 'react-router-dom';

import { getAccount } from '../../actions/account';
import { getBill } from '../../actions/bill';
import { getPaymentInstruments } from '../../actions/instruments';
import { getLateFeeCredit } from '../../actions/latefeecredit';
import { getIsDisconnected } from '../../helpers/account';
import { shouldShowLateFeeCredit } from '../../helpers/lateFeeCredit';
import { formatCurrency } from '../../helpers/formatText';
import { scrollToInvalid } from '../../helpers/errors';
import { setSubmit, setSuccess, setError } from '../../actions/userMessages';

import TrackingRoute from '../TrackingRoute';
import SuccessPushdown from '../../components/SuccessPushdown';
import PermsError from '../Errors/PermsError';
import UnhandledError from '../Errors/UnhandledError';

import AccountTokenNba, { ACCOUNT_TOKEN } from '../../components/nbas/AccountTokenNba';
import MoveBillDueDateNba, { MOVE_BILL_DUE_DATE } from '../../components/nbas/MoveBillDueDateNba';
import { joinClass } from '../../helpers/component';
import { getAutopay, disableAutopay } from '../../actions/autopay';
import Modal from '../../components/Modal';
import Pushdown from '../../components/Pushdown';
import Bill from '../../components/svgs/Bill';
import {
  AUTOPAY,
  AUTOPAY_EDIT,
  AUTOPAY_NEW_BANK,
  AUTOPAY_NEW_CARD,
} from '../../helpers/routes';

import AddInstrument from './AddInstrument';
import Summary from './Summary';
import AutopayForm from './AutopayForm';
import CancelConfirm from './CancelConfirm';

const renderLateFeeCredit = (location = {}, latefeecredit = {}) => {
  const { pathname } = location;

  if (pathname === AUTOPAY || pathname === AUTOPAY_EDIT) {
    return (
      <Pushdown
        title={`Set up automatic payments to get a one-time late fee
          credit of ${formatCurrency(latefeecredit.lateFee)}`}
        icon={(<span className="circle circle--large circle--minty"><Bill className="svg-icon--50" /></span>)}
      >
        {`You'll receive the credit on your next bill after your
          first automatic payment is processed.`}
      </Pushdown>
    );
  }

  return null;
};

class Autopay extends Component {
  constructor() {
    super();

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onDisableAutopay = this.onDisableAutopay.bind(this);
    this.onTermsChecked = this.onTermsChecked.bind(this);

    this.state = {
      modalIsOpen: false,
      termsChecked: false,
    };
  }

  componentDidMount() {
    const {
      handleGetAccount,
      handleGetBill,
      handleGetPaymentInstruments,
      handleGetAutopay,
      handleGetLateFeeCredit,
    } = this.props;

    // needed for billing address for new instrument
    handleGetAccount();
    // needed for if autopay is enabled/bill due amount/date
    handleGetBill();
    // needed for getting instruments/jwk
    handleGetPaymentInstruments();
    // needed for getting autopay instrument even if its a different user
    handleGetAutopay();
    // needed for late fee credit
    handleGetLateFeeCredit();
  }

  onTermsChecked(checked) {
    this.setState({ termsChecked: checked });
  }

  onDisableAutopay() {
    const {
      handleDisableAutopay,
      handleSetSubmit,
      handleSetSuccess,
      handleSetError,
    } = this.props;

    handleSetSubmit();

    return handleDisableAutopay().then(() => {
      this.closeModal();
      handleSetSuccess({
        heading: 'Automatic payments have been turned off successfully.',
        showPushdown: true,
      });
    }).catch((error) => {
      scrollToInvalid();

      handleSetError({ showModal: true });
      throw error;
    });
  }

  openModal() {
    this.setState({ modalIsOpen: true });
  }

  closeModal() {
    this.setState({ modalIsOpen: false });
  }

  renderModal() {
    const { submitting } = this.props;
    const { modalIsOpen } = this.state;

    return (
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={this.closeModal}
        contentLabel="Bank Account Info"
      >
        <div className="text-center" tracking-module="bank-account-routing">
          <h2 className="mb24">Are you sure you want to turn off automatic payments?</h2>

          <div className="action action--right-centered">
            <div className="action__item">
              <button
                type="button"
                className={joinClass('button button--primary', submitting && 'is-loading')}
                onClick={this.onDisableAutopay}
              >
                Turn Off
              </button>
            </div>
            <div className="action__item">
              <button type="button" className="button" onClick={this.closeModal}>Go Back</button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  renderRoutes() {
    const { loading } = this.props;
    if (loading) {
      return <LoadingCard />;
    }

    const {
      bill,
      autopay,
      nextBestActions,
    } = this.props;

    const {
      termsChecked,
    } = this.state;

    return (
      <Switch>
        <TrackingRoute
          exact
          path={AUTOPAY_EDIT}
          render={(matchProps) => {
            if (loading) {
              return <LoadingCard />;
            }

            return (
              <AutopayForm
                onTermsChecked={this.onTermsChecked}
                nextBestActions={nextBestActions}
                {...matchProps}
              />
            );
          }}
        />
        <TrackingRoute
          exact
          path={AUTOPAY_NEW_BANK}
          render={(matchProps) => {
            if (termsChecked) {
              return <AddInstrument nextBestActions={nextBestActions} {...matchProps} />;
            }

            return <Redirect to={AUTOPAY_EDIT} />;
          }}
        />
        <TrackingRoute
          exact
          path={AUTOPAY_NEW_CARD}
          render={(matchProps) => {
            if (termsChecked) {
              return <AddInstrument nextBestActions={nextBestActions} {...matchProps} />;
            }

            return <Redirect to={AUTOPAY_EDIT} />;
          }}
        />
        <TrackingRoute
          render={(matchProps) => {
            if ((bill.summary && bill.summary.autoPayEnabled) || autopay) {
              return (
                <Summary
                  bill={bill}
                  autopay={autopay}
                  openModal={this.openModal}
                  nextBestActions={nextBestActions}
                  {...matchProps}
                />
              );
            }

            return <Redirect to={AUTOPAY_EDIT} />;
          }}
        />
      </Switch>
    );
  }

  render() {
    const {
      cancelConfirm,
      successMessage = {},
      canMakePayment,
      error,
      showLateFeeCredit,
      location,
      latefeecredit,
    } = this.props;

    if (error) {
      return <UnhandledError dismissable={false} />;
    }

    if (!canMakePayment) {
      return (
        <PermsError
          error="!canMakePayment"
        />
      );
    }

    if (cancelConfirm) {
      return <CancelConfirm />;
    }

    return (
      <>
        {successMessage.showPushdown && <SuccessPushdown {...successMessage} />}
        {!successMessage.showPushdown && showLateFeeCredit && (
          renderLateFeeCredit(location, latefeecredit)
        )}

        <PageSection tracking-module="autopay-settings" className="ui-grey">
          {this.renderModal()}
          {this.renderRoutes()}
        </PageSection>
      </>
    );
  }
}

const mapStateToProps = ({
  auth: { macaroon: { mainAccountRoles } },
  bill: { bill, loading: billLoading },
  autopay: {
    autopay,
    cancelConfirm,
    submitting,
    loading: autopayLoading,
    error: autopayError,
  },
  instruments: {
    loading: instrumentsLoading,
    error: instrumentsError,
  },
  account: {
    loading: accountLoading,
    account: {
      prepaid: isPrepaid,
      status,
    } = {},
    error: accountError,
  },
  latefeecredit: { latefeecredit },
  userMessages: {
    success = {},
  },
}) => {
  const nextBestActions = [
    {
      id: ACCOUNT_TOKEN,
      component: AccountTokenNba,
      options: {
        showLink: false,
      },
    },
  ];

  // TODO: make helper
  if (mainAccountRoles.indexOf('PRIMARY') > -1) {
    nextBestActions.push({
      id: MOVE_BILL_DUE_DATE,
      component: MoveBillDueDateNba,
    });
  }

  return {
    bill,
    loading: billLoading || accountLoading || instrumentsLoading || autopayLoading,
    cancelConfirm,
    autopay,
    submitting,
    successMessage: success,
    isDisconnected: getIsDisconnected(status),
    error: instrumentsError || accountError || autopayError,
    latefeecredit,
    showLateFeeCredit: shouldShowLateFeeCredit(bill, latefeecredit),
    nextBestActions,
    canMakePayment: !isPrepaid && !getIsDisconnected(status),
  };
};

const mapDispatchToProps = dispatch => bindActionCreators({
  handleDisableAutopay: disableAutopay,
  handleGetAccount: getAccount,
  handleGetAutopay: getAutopay,
  handleGetBill: getBill,
  handleGetPaymentInstruments: getPaymentInstruments,
  handleGetLateFeeCredit: getLateFeeCredit,
  handleSetSubmit: setSubmit,
  handleSetSuccess: setSuccess,
  handleSetError: setError,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Autopay);
