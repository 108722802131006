import React from 'react';

import PageTitle from './PageTitle';
import Jump from '../containers/Jump';

const AddBank = ({
  canStoreInstruments = true,
  displayStoredPaymentOption = false,
  displayAutoPayEnroll = false,
  enrollInAutopay = false,
  requireStoredPaymentSelection = false,
  selectStoredPaymentOnLoad = false,
}) => (
  <>
    <PageTitle className="heading2">{canStoreInstruments ? 'New bank account' : 'Bank account'}</PageTitle>

    <Jump
      cpcPageType="AchOnly"
      cpcPageCssUrl="/cpc/jump.css"
      shadowCssUrl="/cpc/shadow.css"
      cpcPageWidth="100%"
      termsAndConditionsDisplayOption="popup"
      {...{
        displayStoredPaymentOption,
        displayAutoPayEnroll,
        enrollInAutopay,
        requireStoredPaymentSelection,
        selectStoredPaymentOnLoad,
      }}
    />
  </>
);

export default AddBank;
