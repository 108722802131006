import React from 'react';
import { Switch } from 'react-router-dom';
import DefaultLayout from './containers/Layouts/DefaultLayout';
import DefaultLayoutWithTracking from './containers/Layouts/DefaultLayoutWithTracking';

import NewPayment from './containers/NewPayment';
import Collections from './containers/Collections';
import AutopaySignup from './containers/AutopaySignup';
import Autopay from './containers/Autopay';
import Methods from './containers/ManageInstruments';
import ScheduledPayment from './containers/Scheduled';
import NoMatch from './containers/Errors/NoMatch';
import Terms from './containers/Terms';
import PaymentPlan from './containers/PaymentPlan';
import NoServices from './containers/Errors/NoServices';
import {
  HOME,
  AUTOPAY,
  METHODS,
  NEW_PAYMENT,
  COLLECTIONS,
  SCHEDULED_PAYMENT,
  PAYMENT_SETUP,
  TERMS_AUTOPAY,
  TERMS_STORED_PAYMENT,
  TERMS_ECOBILL,
  PAYMENT_PLAN,
  NOW_METHODS,
  NO_XFINITY_SERVICES,
  NO_NOW_SERVICES,
} from './helpers/routes';
import { flagEnabled } from './helpers/featureFlags';

const AppRoutes = props => (
  <Switch>
    <DefaultLayout exact path={HOME} component={ScheduledPayment} {...props} />
    <DefaultLayout path={NEW_PAYMENT} component={NewPayment} {...props} />
    {flagEnabled('global.collections2', { defaultValue: true }) && <DefaultLayout path={COLLECTIONS} component={Collections} {...props} />}
    <DefaultLayout path={PAYMENT_PLAN} component={PaymentPlan} {...props} />
    <DefaultLayout path={SCHEDULED_PAYMENT} component={ScheduledPayment} {...props} />

    <DefaultLayoutWithTracking path={PAYMENT_SETUP} component={AutopaySignup} {...props} />

    <DefaultLayoutWithTracking path={NO_XFINITY_SERVICES} component={NoServices} productClass="Xfinity" {...props} />
    <DefaultLayoutWithTracking path={NO_NOW_SERVICES} component={NoServices} productClass="NOW" {...props} />
    <DefaultLayout path={[METHODS, NOW_METHODS]} component={Methods} {...props} />
    <DefaultLayout path={AUTOPAY} component={Autopay} {...props} />
    <DefaultLayoutWithTracking path={TERMS_AUTOPAY} component={Terms} contractId="autopay" {...props} />
    <DefaultLayoutWithTracking path={TERMS_STORED_PAYMENT} component={Terms} contractId="stored-payment" {...props} />
    <DefaultLayoutWithTracking path={TERMS_ECOBILL} component={Terms} contractId="ecobill" {...props} />
    <DefaultLayoutWithTracking component={NoMatch} {...props} />
  </Switch>
);

export default AppRoutes;
