import React from 'react';

import PageTitle from './PageTitle';
import Jump from '../containers/Jump';

const AddCard = ({
  displayStoredPaymentOption = false,
  displayAutoPayEnroll = false,
  enrollInAutopay = false,
  requireStoredPaymentSelection = false,
  selectStoredPaymentOnLoad = false,
  isNowPage = false,
}) => (
  <>
    <PageTitle className="heading2">
      {isNowPage ? 'Add a new credit/debit card' : 'New credit/debit card' }

    </PageTitle>
    <Jump
      cpcPageType="CardOnly"
      cpcPageCssUrl="/cpc/jump.css"
      shadowCssUrl="/cpc/shadow.css"
      cpcPageWidth="100%"
      termsAndConditionsDisplayOption="popup"
      newPaymentDisplayType="radio"
      {...{
        displayStoredPaymentOption,
        displayAutoPayEnroll,
        enrollInAutopay,
        requireStoredPaymentSelection,
        selectStoredPaymentOnLoad,
        isNowPage,
      }}
    />
  </>
);

export default AddCard;
