import { passiveAuth, forceAuth } from 'maw-fetch';
import Cookies from 'js-cookie';

import apiClient from '../middleware/apiClient';
import { getSession, checkSessionTimeout } from './session';
import { SKIP_PASSIVE_AUTH_SESSIONS, ALLOWED_AUTH_SESSIONS } from '../helpers/auth';
import {
  getAccountIndex,
  setAccountIndex,
  setCspAccountToken,
  getAccountPerms,
} from '../helpers/account';
import { MACAROON, CONSENT } from '../helpers/apis';
import { flagEnabled } from '../helpers/featureFlags';
import { setGuid } from '../helpers/logger';

// Fetch Macaroon
export const GET_AUTH = 'GET_AUTH';
export const GET_AUTH_SUCCESS = 'GET_AUTH_SUCCESS';
export const GET_AUTH_FAILURE = 'GET_AUTH_FAILURE';

export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SET_FORCE_AUTHENTICATION = 'SET_FORCE_AUTHENTICATION';
export const SET_PERMS_ERROR = 'SET_PERMS_ERROR';
export const SET_ACCOUNT_PERMS = 'SET_ACCOUNT_PERMS';
export const SET_ACCOUNT_INDEX = 'SET_ACCOUNT_INDEX';

function dispatchGetAuth(device = '', crsId = null, showLoading = true) {
  // Handles alternative authentication via consent new user flow (i.e., ?crsId=...)
  if (flagEnabled('consent.enabled') && crsId) {
    return {
      type: GET_AUTH,
      variant: 'CRS',
      payload: apiClient.fetch(CONSENT.RESOLVE(crsId)),
      showLoading,
    };
  }

  // TODO: Remove linkedAccounts query param when removed from MW.
  // The linkedAccounts query param indicates to MW that FE is ready for new linked accounts
  let queryParam = '?linkedAccounts=true';
  if (device === 'ios' || device === 'android') {
    queryParam += `&device=${device}`;
  }
  return {
    type: GET_AUTH,
    payload: apiClient.fetch(MACAROON + queryParam),
    showLoading,
  };
}

function dispatchSetAccountPerms(payload) {
  return {
    type: SET_ACCOUNT_PERMS,
    payload,
  };
}

function dispatchGetAuthSuccess(payload) {
  return {
    type: GET_AUTH_SUCCESS,
    payload,
  };
}

function dispatchGetAuthFailure(error) {
  return {
    type: GET_AUTH_FAILURE,
    payload: error,
  };
}

function dispatchSetAuthStatus(payload) {
  return {
    type: SET_AUTH_STATUS,
    payload,
  };
}

function dispatchSetForceAuthentication() {
  return {
    type: SET_FORCE_AUTHENTICATION,
  };
}

function dispatchSetPermsError() {
  return {
    type: SET_PERMS_ERROR,
  };
}

export const setAuthStatus = status => dispatch => (
  dispatch(dispatchSetAuthStatus(status))
);

function dispatchSetAccountIndex(payload) {
  return {
    type: SET_ACCOUNT_INDEX,
    payload,
  };
}

// Helper wrapped around passiveAuth with error handling
const getPassiveAuth = async (options) => {
  await passiveAuth(options);
  if (Cookies.get('isAuth') === '1') return;

  // If the isAuth cookie isn't set correctly, then something is wrong.
  // Force auth in this case.
  forceAuth(options);

  const authError = new Error('Invalid auth cookie');
  authError.forceAuthentication = true;
  throw authError;
};

export const getAuth = (device, showLoading = true) => async (dispatch, getState) => {
  const { auth: { crsId } = {} } = getState();
  const isConsent = flagEnabled('consent.enabled') && crsId;
  try {
    let session = await dispatch(getSession());
    if (SKIP_PASSIVE_AUTH_SESSIONS.indexOf(session.status) === -1) {
      await getPassiveAuth();
    }

    const response = await dispatch(dispatchGetAuth(device, crsId, showLoading)).payload;
    session = await dispatch(getSession());

    if (ALLOWED_AUTH_SESSIONS.indexOf(session.status) === -1) {
      throw new Error(`invalid session status: ${session.status}`);
    }

    dispatch(setAuthStatus(session.status));
    if (isConsent) {
      return dispatch(dispatchGetAuthSuccess(response));
    }
    dispatch(checkSessionTimeout(session));

    // On initial page load don't validate the account index, just set it.
    // The first time getAuth is called auth.accountIndex should be undefined.
    // On reauth, auth.accountIndex should be set, so we should
    // validate it didn't change in setAccountIndex.
    const { auth: { accountIndex: currentAccountIndex } = {} } = getState();
    const validateAccountIndex = Number.isInteger(currentAccountIndex);

    // Set the CSP Auth header for the selected account once macaroon returns.
    const accountIndex = getAccountIndex(response);
    setAccountIndex(accountIndex, validateAccountIndex);
    dispatch(dispatchSetAccountIndex(accountIndex));
    setCspAccountToken(response, accountIndex);

    setGuid(response.guid);

    const { lite } = response;

    if (lite) {
      return dispatch(dispatchGetAuthSuccess(response));
    }

    await dispatch(dispatchSetAccountPerms(getAccountPerms(response, accountIndex)));

    const { auth: { accountPerms } } = getState();

    const result = dispatch(dispatchGetAuthSuccess(response));

    if (!accountPerms.some(item => ['PRIMARY', 'BILLPAY'].indexOf(item) > -1)) {
      dispatch(dispatchSetPermsError());
    }
    return result;
  } catch (error) {
    if (error.forceAuthentication) {
      dispatch(dispatchSetForceAuthentication());
      throw error;
    }

    if (isConsent) {
      localStorage.removeItem('crsId');
    }

    dispatch(dispatchGetAuthFailure(error));
    throw error;
  }
};

export const addRefreshAuthListener = () => (dispatch, getState) => {
  window.addEventListener('refreshAuth', async ({ authPromise }) => {
    const { auth: { isLite, crsId } = {}, harness: { device } } = getState();

    if (isLite || (flagEnabled('consent.enabled') && crsId)) {
      return authPromise.reject();
    }
    try {
      await dispatch(getAuth(device, false));
      return authPromise.resolve();
    } catch (error) {
      authPromise.reject(error);
      throw error;
    }
  });
};
